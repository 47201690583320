import ApiService from '@/services/api'
import { defineStore } from 'pinia'
//import { taskUpdateService } from '@/rxjsServices';

const ENDPOINT = '/types/'
const ITEM_ID  = 'typ_id'

export const useTypeStore = defineStore('types', {
    //storage option
    persist: true,
    // convert to a function
    state: () => ({
        item: null,
        itemList: [],
        itemSearchList: ''
    }),
    getters: {
      itemListByGroup() {
        let itemsObject = {}
        for (let i=0, len=this.itemList.length; i<len; i++){
          if( !this.itemList[i].typ_visibility ){ continue; }
          if( !itemsObject[ this.itemList[i].typ_id.substring(0, 3) ] ){
            itemsObject[ this.itemList[i].typ_id.substring(0, 3) ] = []
          }
          itemsObject[ this.itemList[i].typ_id.substring(0, 3) ].push(this.itemList[i])
        }
        return itemsObject || {}
      },
      itemListById() {
        let itemsObject = {}
        for (let i=0, len=this.itemList.length; i<len; i++){
          if( !this.itemList[i].typ_visibility ){ continue; }
          itemsObject[ this.itemList[i].typ_id ] = this.itemList[i]
        }
        return itemsObject
      }
    },
    actions: {
        fetchItem (id, options) {
          return new Promise( (resolve, reject) => {
              options = (options) ? '/' + options : ''
              if( id && id.length > 3 ){
                  ApiService.get(ENDPOINT + id + options).then( (response) => {
                      if( !response.data.data.sit_customfields || Object.prototype.toString.call( response.data.data.sit_customfields ) === '[object Array]' ) {
                          response.data.data.sit_customfields = {}
                      }
                      this.SET_ITEM(response.data.data)
                      resolve(this.item)
                  }).catch( (error) => { 
                      reject(error)
                  })
              } else {
                  this.RESET_ITEM()
                  resolve(this.item)
              }
          })
        },
        fetchItems (options) {
            return new Promise( (resolve, reject) => {
              options = (options) ? options : ''
              ApiService.get( ENDPOINT + options ).then( (response) => {
                  this.metaTask = response.data.meta
                  if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                  this.SET_ITEMS(response.data.data)
                  }
                  resolve('resolved')
              }).catch( (error) => { 
                  reject(error)
              })
            })
        },
        storeItem () {
            return new Promise( (resolve, reject) => {
              ApiService.post( ENDPOINT, this.item ).then( (response) => {
                this.SET_ITEM(response.data.data)
                resolve(this.item)
              }).catch( (error) => { 
                reject(error)
              })
            })
          },
        updateItem () {
          return new Promise( (resolve, reject) => {
            ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
              this.SET_ITEM(response.data.data)
              resolve(this.item)
            }).catch( (error) => { 
              reject(error)
            })
          })
        },
        destroyItem (id) {
          return new Promise( (resolve, reject) => {
            this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
            ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
              resolve('resolved')
            }).catch( (error) => { 
              reject(error)
            })
          })
        },
        //***************************MUTATIONS ***************************************/
        SET_ITEM (item) {
        this.item = item
        },
        SET_ITEMS (itemList) {
        this.itemList = itemList
        },
        RESET_ITEM () {
            this.item = { typ_id: '', typ_color: '' }
        }
    }
})
