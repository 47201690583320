<template>
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" class="ma-4 rounded-lg elevation-2">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                    <v-btn color="success" depressed rounded class="white--text" to="/companies/0">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.cpy_name`]="{ item }">
            <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.cpy_name }}</strong>
        </template>
        <template v-slot:[`item.cpy_sta_id`]="{ item }">
            <v-chip small outlined label :color="stateListById[item.cpy_sta_id].sta_color">
                {{ stateListById[item.cpy_sta_id].sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.cpy_active`]="{ item }">
            <v-icon v-if="item.cpy_active" large color="green darken-2">mdi-check-all</v-icon>
            <v-icon v-else large color="red darken-2">mdi-close-octagon</v-icon>
        </template>
        <template v-slot:[`item.cpy_created_at`]="{ item }">
            <v-chip label outlined>{{ $date(item.cpy_created_at).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.usr_id_count`]="{ item }">
            <v-badge bordered color="error" overlap :content="item.usr_id_count">
                <v-icon large >mdi-account</v-icon>
            </v-badge>
        </template>
        <template v-slot:[`item.cpy_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/companies/' + item.cpy_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState } from 'pinia'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

export default {
    name: 'companies_edit',
    props: [ ],
    mixins: [ ],
    computed: {
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    data: function () {
        return {
            loading: { getgetTaskItems: false },
            validFrom: true,
            items        : [],
            taskSelected : {},
            selectFields : {},
            actionTask   : [],
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',     align: 'left', value: 'cpy_name'},
                { text: 'Statut',  align: 'left', value: 'cpy_sta_id'},
                { text: 'Activée', align: 'left', value: 'cpy_active'},
                { text: 'Utilisateur(s)', align: 'center', value: 'usr_id_count'},
                { text: 'Création',  align: 'left', value: 'cpy_created_at'},
                { text: 'Action',  align: 'right', value: 'cpy_id'},
            ],
        }
    },
    beforeMount: function(){
        this.$http.get('/companies/?per_page=false&extended=viewall,withcountuser').then( (response) => {
            this.items = response.data.data
        })
    },
    mounted: function (){

    },
    methods: {

    },
}
</script>