<template>
<v-container fluid class="pa-0" > <!-- @scroll="onScroll" -->
    <PageHeader :contextStyle="contextStyle" >
        <!-- <template #right><v-btn small rounded color="primary" href="#/tasks/0">Ajouter une intervention</v-btn></template> -->
    </PageHeader>
    <TasksForm v-if="( ($route.params.tsk_uid == 0) || ($route.params.tsk_uid !== undefined && $route.params.tsk_uid.length > 8) )" :link="link" />
    <v-card v-else class="ma-4 rounded-lg">
        <TasksList :inSlider="false" />
    </v-card>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import TasksList from '@/components/tasks/TaskList'
import TasksForm from '@/components/tasks/TaskForm'

export default {
    name: 'tasks-page',
    props: ['contextStyle'],
    mixins: [ ],
    components: {
        PageHeader, TasksList, TasksForm
    },
    data: function () {
        return {
            link: { base: 'tasks' }
        }
    },
    computed: {

    },
    mounted: function (){

    },
    methods: {
        onScroll(e) {
            console.log(e)
			const { scrollTop, offsetHeight, scrollHeight } = e.target
			if ((scrollTop + offsetHeight) >= scrollHeight) {
				console.log('bottom!')
			}
    }
    }
}
</script>