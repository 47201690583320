<template>
<v-container fluid class="pa-1">
    <v-toolbar class="elevation-0" height="48px">
        <v-toolbar-title >Détail du temps</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="(task.tsk_sta_id !== 'GEN03')"
            outlined rounded small :color="(true) ? 'grey' : 'green'" @click="openWorktime(0)">
            Ajouter<v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
    </v-toolbar>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="sta_id"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getWorktime"> 
        <template v-slot:[`item.usr_firstName`]="{ item }">
            <v-chip class="ma-1 " small outlined color="secondary"><v-icon>mdi-account</v-icon>&nbsp;{{ item.usr_lastName.substring(0,1) + '. ' + item.usr_firstName }}</v-chip><br />
        </template>
        <template v-slot:[`item.wti_date`]="{ item }" >
            <v-chip class="ma-1 " small outlined color="secondary"><v-icon>mdi-clipboard-text-clock-outline</v-icon>&nbsp;{{ $date(item.wti_date_start).format('DD/MM/YYYY') }}</v-chip><br />
        </template>
        <template v-slot:[`item.wti_duration`]="{ item }" >
            <v-chip class="ma-1" outlined color="secondary"><v-icon>mdi-clock-outline</v-icon>&nbsp;{{ item.wti_duration.substring(0, 5) }}</v-chip>&nbsp;
            <v-icon v-if="(item.wti_typ_id == 'WTI01')" :color="typeListById[item.wti_typ_id].typ_color" :title="typeListById[item.wti_typ_id].typ_name">
                mdi-mower-on
            </v-icon>
            <v-icon v-else :color="typeListById[item.wti_typ_id].typ_color" :title="typeListById[item.wti_typ_id].typ_name">
                mdi-home-city-outline
            </v-icon>
        </template>
        <template v-slot:[`item.wti_id`]="{ item }" >
            <v-icon 
                v-if="(task.tsk_sta_id == 'GEN01') && !item.wti_validated" 
                left color='orange' title="Editer" @click="openWorktime(item.wti_id)">
                mdi-pencil
            </v-icon>
            <v-icon v-else :color="typeListById[item.wti_typ_id].typ_color" title="Voir" @click="openWorktime(item.wti_id)">
                mdi-eye
            </v-icon>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
        <template slot="no-data">
        <v-alert :value="( !loading.getWorktime )" color="error" icon="mdi-alert">
            Aucune saisie
        </v-alert>
        </template>
    </v-data-table>

    <v-dialog v-model="modalLayer" max-width="900" >
        <!-- <tab-layer v-if="modalLayer" :doc_id="doc_id" :switchModal="switchModal"></tab-layer> -->
    </v-dialog>
    <v-dialog v-model="modalWorktime" hide-overlay max-width="900" content-class="rounded-xxl micorise-v-card">
        <v-card color="grey lighten-4" min-width="350px" elevation="0" class="pa-0 elevation-0 rounded-xxl micorise-v-card" style="background-color: white">
        <v-toolbar color="secondary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Temps de travail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalWorktime', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 pa-0">
            <WorktimeForm
                v-if="modalWorktime" :switchModal="switchModal"
                :wti_id="wti_id" :tsk_id="task.tsk_id">
            </WorktimeForm>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import WorktimeForm from './WorktimeForm.vue'

import { mapState, mapActions } from 'pinia'
import { useWorkTimeStore } from '@/stores/worktime'
import { useTypeStore } from '@/stores/type'

export default {
    name: 'worktime-list',
    props: ['table_id', 'task'],
    components: {
        WorktimeForm
    },
    computed: {
        ...mapState(useWorkTimeStore, {itemList: 'itemList' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
    },
    data: function () {
      return {
            loading: { uploadBtn: false, getWorktime: false },         // Enregistrement en cours...
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Utilisateurs', align: 'left', value: 'usr_firstName'},
            { text: 'Date',         align: 'left', value: 'wti_date'},
            { text: 'Temps',        value: 'wti_duration' },
            { text: 'Action',        value: 'wti_id' }
            ],
            enableAdd: true,
            modalLayer : false,
            modalWorktime: false,
      }
    },
    mounted: function (){
        this.fetchItems('?wti_tsk_id=' + this.task.tsk_id)
    },
    methods: {
        ...mapActions(useWorkTimeStore, { fetchItems: 'fetchItems' }),
        openWorktime(id){
            this.wti_id = id
            this.switchModal('modalWorktime', 1)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalWorktime'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){
                this.fetchItems('?wti_tsk_id=' + this.task.tsk_id)
            }
        }
    }
}
</script>