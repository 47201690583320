<template>
    <v-container fluid class="pa-0">
        <PageHeader :contextStyle="contextStyle">
            <!-- <template #right><v-btn small rounded color="primary" href="#/sites/0">Ajouter un site</v-btn></template> -->
        </PageHeader>
        <v-tabs v-if="showTab" v-model="activeTab" grow light show-arrows="mobile" slider-size="1">
            <v-tabs-slider color="green"></v-tabs-slider>
            <v-tab v-for="item in tabItems" :key="item.title" :disabled="item.disabled"
                class="pl-1 pr-1 rounded-lg" style="border-bottom-left-radius: 20px !important; background-color:white;">
                <span class="hidden-sm-and-down"><v-icon class="mr-1" :color="(activeTab.event == 0) ? 'red'  : 'grey'">{{item.icon}}</v-icon>
                    {{ item.title }}
                </span>
            </v-tab>
        </v-tabs>
        <SponsorshipForm  v-if="activeTab === 0"/>
        <SponsorshipTrade v-if="activeTab === 1" />
        <v-card class="ma-4 rounded-lg">
            <SponsorshipList />
        </v-card>
    </v-container>
</template>
    
<script>
import PageHeader from '@/components/ui/PageHeader'
import SponsorshipList  from '@/components/sponsorships/SponsorshipList'
import SponsorshipForm   from '@/components/sponsorships/SponsorshipForm'
import SponsorshipTrade   from '@/components/sponsorships/SponsorshipTrade'

export default {
    name: 'sponsorships-page',
    props: ['contextStyle'],
    components: { PageHeader, SponsorshipForm, SponsorshipList, SponsorshipTrade },
    data: function () {
        return {
            loading: { fetchData: true },
            showTab: true,
            tabItems: [
                { title: 'Inviter',      disabled: false, icon: 'mdi-account-multiple-plus-outline'},
                { title: 'Utiliser mes crédits',      disabled: false, icon: 'mdi-ticket'},
            ],
            activeTab: -1,
        }
    },
    computed: {

    },
    mounted:function (){
        this.loading.fetchData = false
        this.activeTab = 0
    },
    methods: {
        SendInvite () {
            this.$root.$toast({ color: 'success', text: 'Invitation envoyée !' })
        },
    },
}
</script>