/* *****************************
    Objet : Task
******************************** */
//import { cacheGetters } from "@/stores/cache.module"

export const tskObj = {
    data: function () {
        return {
            objTask: {
                tsk_id           : 0,
                tsk_uid          : '',
                tsk_zon_id       : '',
                tsk_sar_id       : null,
                tsk_tty_id       : null,
                tsk_sta_id       : 'TSK04',
                tsk_typ_id       : 'TSK01',
                tsk_typ_id_origin: 'TSO01',
                tsk_date_plan    : null,
                tsk_date_end     : null,
                tsk_date_close   : null,
                tsk_customfields : {},
                tsk_ext_code     : '',
                tsk_amount_ht    : '',
                tsk_duration     : 0,
                tsk_usr_id_created: 0,
                tsk_usr_id_updated: 0,
                tsk_created_at    : 0,
                tasktype : null,
                tse_tsk_id_count: 0,
                _table: {},
                _data:  { equipments: [] },
                _pivot: { equipments: [] },
                _info: { equipments: {id: 'equ_id'}, document: {}, event: {} }
            },
            objTaskClone: {},
            listTask: [],
            metaTask: {},
            //bookmark: cacheGetters.getBookmark()
        }
    },
    computed: {
        
    },
    beforeMount: function (){
        this.objTaskClone = JSON.stringify( this.objTask )
    },
    methods: {
        getTaskShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/' + options
                }
                this.$http.get( '/tasks/' + this.objTask.tsk_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( Object.prototype.toString.call(response.data.data[prop]) === '[object Array]' && this.objTask._data[prop] ){
                            //this.objTask._info[prop] = response.data.data[prop]['data']
                            this.objTask._data[prop] = response.data.data[prop]
                            this.objTask._pivot[prop] = [...this.objTask._data[prop].map(a => a[this.objTask._info[prop].id])]
                        } else {
                            this.objTask[prop] = response.data.data[prop]
                        }
                    }
                    this.objTask.tsk_customfields = (!this.objTask.tsk_customfields) ? {} : this.objTask.tsk_customfields
                    if( Object.prototype.toString.call( this.objTask.tsk_customfields ) === '[object Array]' ) {
                        this.objTask.tsk_customfields = {}
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getTaskIndex(page, perPage, options){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/tasks/' + options ).then( (response) => {
                    this.metaTask = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listTask = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postTaskStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/tasks', this.objTask ).then( (response) => {
                    this.objTask.tsk_id = (response.data.data) ? response.data.data.tsk_id : response.data.tsk_id
                    resolve('resolved')
                    
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putTaskStore(obj){
            return new Promise( (resolve, reject) => {
                obj = (obj) ? obj : this.objTask // partial update
                this.$http.put( '/tasks/' + obj.tsk_id, obj ).then( (response) => {
                    this.objTask.tsk_id = (response.data.data) ? response.data.data.tsk_id : response.data.tsk_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteTaskDestroy(id){
            return new Promise( (resolve, reject) => {
                this.objTask.tsk_id = (id) ? id : this.objTask.tsk_id
                this.$http.delete( '/tasks/' + this.objTask.tsk_id ).then( (response) => {
                    this.objTask.tsk_id = response.data.tsk_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        checkTaskState(obj){
            return new Promise( (resolve) => {
                obj = (obj) ? obj : this.objTask
                if( obj.tsk_date_pro && obj.tsk_date_pro !== '0000-00-00' ){
                    obj.tsk_typ_id = 'TSK05'
                } else if( obj.tsk_date_fou && obj.tsk_date_fou !== '0000-00-00' ){
                    obj.tsk_typ_id = 'TSK04'
                } else if( obj.tsk_date_ess && obj.tsk_date_ess !== '0000-00-00' ){
                    obj.tsk_typ_id = 'TSK03'
                } else if( obj.tsk_date_plan && obj.tsk_date_plan !== '0000-00-00' ){
                    obj.tsk_typ_id = 'TSK02'
                } else {
                    obj.tsk_typ_id = 'TSK01'
                }
                resolve('resolved')
            })
        },
        cloneTaskObj(){
            this.objTaskClone = JSON.stringify( this.objTask )
        },
        cleanTaskObj(){
            var taskClone = JSON.parse( this.objTaskClone )
            var prop
            for( prop in taskClone ){
                if( this.objTask[prop] !== undefined ){
                    this.objTask[prop] = taskClone[prop]
                }
            }
        },
    }
}