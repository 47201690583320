<template>
    <v-container fluid class="pa-0">
    <!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card">
        <v-card-title >
            {{ (appCode !== 'ruc') ? 'Site' : 'Nide de frelons' }}
            <v-spacer></v-spacer>
            <v-chip outlined color="secondary">
                    <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.updatedAt).format('DD MMMM YYYY') }}
            </v-chip>
        </v-card-title>
        <v-layout row wrap class="layout-flex-2" style="">
            <v-flex xs8>
                <v-text-field outlined dense class="rounded-lg" label="Nom" v-model="item.sit_name" ></v-text-field>
            </v-flex>
            <!-- right side -->
            <v-flex xs4>

            </v-flex>
            <v-flex xs4>
                <AutocompleteMulti v-if="!loading.fetchData"
                :title="(appCode !== 'ruc') ? 'Client' : 'Mairie'"
                :listObject.sync="item.sit_ent_id" :listParent.sync="listEntities" 
                ressource="entities"
                :notfoundClick="createNewCustomer"
                :fields="{ 
                    index: 'ent_id', text:'ent_name', autofocus: true, outlined: true, dense: true, notfound: (appCode !== 'ruc') ? 'Nouveau Client' : 'Ajouter Mairie',
                    search:'ent_name', multiSelect:false, prefetch: true, resultlimit: 'false', params:''
                }"></AutocompleteMulti>
            </v-flex>
            <v-flex xs4>
                <AutocompleteMulti v-if="(!loading.fetchData && appCode !== 'ruc')" :disabled="true"
                :title="'Restriction Agence'"
                :listObject.sync="item.sit_agc_id" :listParent.sync="listAgencies" 
                ressource="agencies"
                :fields="{ 
                    index: 'agc_id', text:'agc_name', autofocus: true, outlined: true, dense: true,
                    search:'agc_name', multiSelect:false, prefetch: false, resultlimit: 'false', params:''
                }"></AutocompleteMulti>
            </v-flex>
            <!-- right side -->
            <v-flex xs4></v-flex>
            <v-flex xs8>
                <v-autocomplete
                    v-model.trim="searchAddress"
                    label="Rechercher adresse"
                    :items="addressTab"
                    item-value="id" item-text="label"
                    :search-input.sync="search"
                    @change="selectAdress" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
                    append-icon="mdi-magnify" filled outlined dense clearable no-filter
                    class="inline rounded-lg shrink expanding-search"
                    background-color="white">
                </v-autocomplete>
            </v-flex>
            <!-- right side -->
            <v-flex xs4></v-flex>
            <v-flex xs4>
                <v-text-field ref="address" outlined dense class="rounded-lg" label="Adresse complément" v-model="item.sit_address"></v-text-field>
            </v-flex>
            <v-flex xs3>
                <v-text-field outlined dense class="rounded-lg" label="Ville" v-model="item.sit_town"></v-text-field>
            </v-flex>
            <v-flex xs1>
                <v-text-field outlined dense class="rounded-lg" label="Code postal" v-model="item.sit_zip"></v-text-field>
            </v-flex>
            <!-- right side -->
            <v-flex xs4></v-flex>
            <v-flex xs8>
                <v-textarea outlined dense class="rounded-lg" label="Description" v-model="item.sit_description"></v-textarea>
            </v-flex>
            <v-flex xs4>
                
            </v-flex>
        </v-layout>
        <v-card-actions>
            <v-btn class="rounded-xl" large color="red" v-if="item.uid" @click="deleteItem(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn class="rounded-xl" large color="primmary" href="/#/sites/">Retour</v-btn>
            <v-btn class="rounded-xl" large color="success" :loading="loading.fetchData" @click.native="saveItem()" :disabled="!validForm">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
    </v-container>
</template>
    
<script>
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { mapWritableState, mapActions } from 'pinia'
import { useSiteStore } from '@/stores/site'

import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'sites-edit',
    components: { AutocompleteMulti },
    computed: {
        ...mapWritableState(useSiteStore, {item: 'item' }),
        validForm(){
            let valid = true
            valid = (!this.item.sit_ent_id) ? false : valid
            valid = (this.loading.fetchData) ? false : valid
            valid = (!this.item.sit_address.length > 2) ? false : valid
            return valid
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val && val.length >= 3 ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    data: function () {
        return {
            appCode: process.env.VUE_APP_NAME,
            loading: { fetchData: true },
            listEntities: [], listAgencies: [],
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
        }
    },
    async mounted () {
        await this.fetchItem( this.$route.params.sit_id, '?include=entity' )
        if(this.item.entity){ this.listEntities.push( this.item.entity ) }
        if( this.$route.params.lat && this.$route.params.lng ){
            this.adresseFromPoint( { lat: this.$route.params.lat, lng: this.$route.params.lng } )
        }
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useSiteStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem'}),
        async saveItem () {
            // Enregistrement du formulaire
            this.loading.fetchData = true
            if( parseInt( this.$route.params.sit_id ) > 0 ){
                await this.updateItem()
            } else {
                await this.storeItem()
            }
            //this.$router.push({ path: '/sites' })
            this.$router.push({ name: this.$route.matched[1].name + '-slide-site', params: { ent_id: this.$route.params.ent_id, sit_id: this.item.sit_id } })
            filterUpdateService.sendFilter('filter')
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        adresseFromPoint(latLng){
            this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/reverse/?lon=" + latLng.lng + "&lat=" + latLng.lat + ""
                }).then( (response) => {
                    
                    if( response.data && response.data.features && response.data.features[0] ){

                        this.item.sit_address = response.data?.features[0]?.properties.name
                        this.item.sit_town    = response.data?.features[0]?.properties.city
                        this.item.sit_zip     = response.data?.features[0]?.properties.postcode
                        this.item.sit_lat     = latLng.lat
                        this.item.sit_lng     = latLng.lng
                    }
                })
        },
        selectAdress(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                this.item.sit_address = this.addressTab[this.searchAddress]?.item?.properties?.name
                this.item.sit_town = this.addressTab[this.searchAddress]?.item?.properties?.city
                this.item.sit_zip = this.addressTab[this.searchAddress]?.item?.properties?.postcode
                this.item.sit_lat = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[1]
                this.item.sit_lng = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[0]
            }
            this.searchAddress = ''
            this.addressTab.splice(0)
            this.$refs.address.focus()
        },
        createNewCustomer(){
            this.$router.push({ name: 'map-view-slide-customer-create', params: {
                ent_id: 0, sit_id: this.$route.params.sit_id,
                lat: this.$route.params.lat,
                lng: this.$route.params.lng,
            } })
        }
    }
}
</script>