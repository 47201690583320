<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
    </PageHeader>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom"  v-model="item.cpy_name"></v-text-field>
        </v-flex>

        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="stateListByGroup['GEN']" v-model="item.cpy_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="R.I.B."  v-model="item.cpy_customfields.rib"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Siret"  v-model="item.cpy_siret"></v-text-field>
        </v-flex>
        
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn :disabled="!item.cpy_id" rounded color="error" @click.native="deleteItem()"><v-icon left dark>mdi-close</v-icon>Supprimer</v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text href="/#/companies/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="loading.saveProgress" @click.native="saveItem()" :disabled="loading.saveProgress">
            <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useCompanyStore } from '@/stores/company'

import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

export default {
    name: 'Companies-Edit',
    props: [ 'contextStyle'],
    components: { PageHeader },
    data: function () {
        return {
            loading: { fetchData: false, saveProgress: false },
        }
    },
    computed: {
        ...mapWritableState(useCompanyStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    async mounted (){
        await this.fetchItem( this.$route.params.cpy_id, '?ent_sta_id=GEN01' )
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useCompanyStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem: 'destroyItem'}),
        async saveItem () {
            // Enregistrement du formulaire
            this.loading.fetchData = true
            if( parseInt( this.$route.params.cpy_id ) > 0 ){
                this.updateItem()
            } else {
                this.storeItem()
            }
            this.$router.push({ path: '/companies' })
        },
        async deleteItem(){
            await this.destroyItem()
            this.$router.push({ path: '/companies' })
        },
    }
}
</script>