<template>
<v-container fluid class="pa-0 ma-0">
<v-alert class="ma-5" border="right" colored-border type="error" elevation="2">
    Merci de contacter l’administrateur pour plus d’information.
</v-alert>
</v-container>
</template>

<script>
export default {
    name: 'forbiden-component'
}
</script>