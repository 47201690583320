<template>
<v-app id="singin" light style="background-color: #b6c9c0;">
    <v-layout row wrap style="margin-top:1em;" class="ma-5 rounded-xxl elevation-2">
        <v-flex xs5 class="pa-0 rounded-xxl rounded-r-0" style="background-color: #3d946d;">
            <SignInPlan :planId="item.cpy_sub_typ_id" />
        </v-flex>
        <v-flex xs7 class="pa-0 rounded-xxl rounded-l-0" style="background-color: #ffffff;">
            <v-card fluid class="ma-4 pa-0 elevation-0">
                <v-card-title class="justify-center text-uppercase font-weight-bold text-h4">Création de compte</v-card-title>
            </v-card>
            <v-card v-if="!signSuccess" fluid class="ma-4 pa-0 elevation-1">
                <v-card-title class="justify-center text-uppercase grey--text font-weight-bold">Vos identifiants :</v-card-title>
                <v-layout row wrap>
                <v-flex xs6 class="pl-6 pr-6"><v-text-field outlined dense class="rounded-lg" color="red" label="Nom"  v-model="item.firstName"></v-text-field></v-flex>
                <v-flex xs6 class="pl-6 pr-6"><v-text-field outlined dense class="rounded-lg" label="Prénom"  v-model="item.lastName"></v-text-field></v-flex>
                <v-flex xs12 class="pl-6 pr-6"><v-text-field outlined dense class="rounded-lg" prepend-inner-icon="mdi-email-outline" label="Email"  v-model="item.email"></v-text-field></v-flex>
                <!--<v-flex xs6 class="pl-6 pr-6">
                    <v-text-field outlined dense class="rounded-lg" prepend-inner-icon="mdi-lock-outline" label="Mot de passe" type="password" v-model="item.password" loading>
                    <template v-slot:progress>
                    <v-progress-linear :value="progress" :color="color" height="3" absolute class="mt-2"></v-progress-linear>
                    </template>
                    </v-text-field>
                </v-flex>-->
                <v-flex xs8 class="pt-3 pl-5 pr-5" v-if="item.cpy_sub_typ_id == 'SUB01'">
                    <v-file-input @change="getFileDocument" ref="documentfile" v-model="item.obj_file" show-size label="Joindre la carte étudiant"></v-file-input>
                </v-flex>
                <v-flex xs4 class="pt-3 pl-5 pr-5" v-if="item.cpy_sub_typ_id == 'SUB01'">
                    <v-select item-text="label" item-value="value" outlined dense class="rounded-lg"
                        :items="[{label: 'BT', value:'URO03'}, {label: 'BTS', value:'URO04'}, {label: 'Licence', value:'URO05'}]" 
                        v-model="item.origin" label="Etude" single-line persistent-hint hint="Cursus scolaire">
                    </v-select>
                </v-flex>

                <v-flex xs12 class="pt-3 pl-5 pr-5"><v-divider></v-divider></v-flex>
                </v-layout>

                <v-card-title class="mt-3 justify-center text-uppercase grey--text font-weight-bold">Votre société :</v-card-title>
                <v-layout row wrap>
                <v-flex xs8 class="pl-6 pr-6">
                    <v-text-field outlined dense class="rounded-lg" label="Nom"  v-model="item.cpy_name"></v-text-field>
                </v-flex>
                <v-flex xs4 class="pl-6 pr-6">
                    <v-text-field outlined dense class="rounded-lg" label="Siret"  v-model="item.cpy_siret"></v-text-field>
                </v-flex>
                <!--<v-flex xs6 class="pl-6 pr-6">
                    <v-text-field outlined dense class="rounded-lg" label="R.I.B."  v-model="item.cpy_customfields.rib"></v-text-field>
                </v-flex>-->
                <!-- Adresse ------------------->
                <v-flex xs12 class="pl-6 pr-6">
                    <v-autocomplete
                        v-model.trim="searchAddress"
                        label="Rechercher adresse"
                        :items="addressTab"
                        item-value="id" item-text="label"
                        :search-input.sync="search"
                        @change="selectAdress" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
                        append-icon="mdi-magnify" filled outlined dense clearable no-filter
                        class="inline rounded-lg shrink expanding-search"
                        background-color="white">
                    </v-autocomplete>
                </v-flex>
                <!-- right side -->
                <v-flex xs5 class="pl-6 pr-6">
                    <v-text-field ref="address" outlined dense class="rounded-lg" label="Adresse / complément" v-model="item.cpy_address"></v-text-field>
                </v-flex>
                <v-flex xs4 class="pl-6 pr-6">
                    <v-text-field outlined dense class="rounded-lg" label="Ville" v-model="item.cpy_town"></v-text-field>
                </v-flex>
                <v-flex xs3 class="pl-6 pr-6">
                    <v-text-field outlined dense class="rounded-lg" label="Code postal" v-model="item.cpy_zip"></v-text-field>
                </v-flex>
                <v-flex xs8 class="pl-6 pr-6">
                    <v-select item-text="label" item-value="value" outlined dense class="rounded-lg"
                        :items="cacheState" v-model="item.cpy_sub_typ_id" label="Abonnement" single-line persistent-hint hint="Type d'abonnement">
                    </v-select>
                </v-flex>
                <v-flex xs4 class="pl-6 pr-6">
                    <v-text-field 
                        outlined dense class="rounded-lg" 
                        prepend-inner-icon="mdi-card-account-details-star-outline" 
                        persistent-hint :hint="'Code parrainage (' + sponsorCompany + ')'" label="Code" v-model="item.spo_code">
                    </v-text-field>
                </v-flex>
                <v-flex xs6 class="pl-6 pr-6">
                    <v-text-field ref="captcha" outlined dense class="rounded-lg" label="Captcha" v-model="item.captcha"></v-text-field>
                </v-flex>
                <v-flex xs4 class="pl-6 pr-6" style="text-align: right;">
                    <img v-if="captchaReady" ref="imgSrc" :src="captchaImageSrc" />
                </v-flex>
                <v-flex xs2 class="pl-6 pr-6">
                    <v-btn depressed color="grey-lighten" min-height="45" class="ml-2" @click.native="getCaptcha()" title="Recharger le code">
                        <v-icon left dark>mdi-reload</v-icon>
                    </v-btn>
                </v-flex>
                </v-layout>

                <v-card-actions extended flat color="transparent" class="justify-center">
                    <v-btn rounded color="primary" class="mr-3 ml-3" :loading="loading.fetchData" @click.native="register()" :disabled="!validForm">
                        Valider
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="signSuccess" fluid class="ma-4 pa-0 elevation-1">
                <v-card-title class="grey--text font-weight-bold" style="word-break: keep-all;">
                    Vous avez reçu un e-mail avec un lien pour terminer votre inscription.
                </v-card-title>
            </v-card>
        </v-flex>
    </v-layout>
    <ToastDialog ref="toast" />
</v-app>
</template>
    
<script>
import { mapState, mapActions } from 'pinia'
import { useSponsorshipStore } from '@/stores/sponsorship'

import ToastDialog  from '@/components/ui/Toast.vue'
import SignInPlan  from '@/layouts/SignPlans.vue'

export default {
    name: 'singin-component',
    components: { ToastDialog, SignInPlan },
    data () {
        return {
            toastDefault: { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
            toastOption:  { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
            loading: { fetchData: false, saveProgress: false },
            item: {
                firstName: '', lastName: '', email: '', password: '',
                cpy_name: '', cpy_sub_typ_id: 'FO', cpy_customfields: {},
                obj_file: null, doc_filename: '', doc_name: '', spo_code: '', spo_uid: ''
            },
            cacheState: [ 
                { label: 'Folium (basic)',         id: 'FO', value: 'SUB02' },
                { label: 'Arbor (Intermédaire)',   id: 'AR', value: 'SUB03' },
                { label: 'Silva (Toutes options)', id: 'SI', value: 'SUB04' },
                { label: 'Seminis (Etudiants)',    id: 'SE', value: 'SUB01' },
            ],
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            captchaImageSrc: '',
            captchaReady: false,
            signSuccess: false,
            sponsorCompany: ''
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val && val.length >= 3 ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    computed: {
        ...mapState(useSponsorshipStore, {mySponsor: 'item' }),
        validForm(){
            let valid = true
            valid = (!this.item.firstName.length > 2) ? false : valid
            valid = (!this.item.lastName.length > 0) ? false : valid
            valid = (!this.item.email.length > 0) ? false : valid
            //valid = (!this.item.password.length > 0) ? false : valid
            valid = (!this.item.cpy_name.length > 0) ? false : valid
            valid = (this.loading.fetchData) ? false : valid
            return valid
        },
        progress () {
            return (this.item.password) ? Math.min(100, this.item.password.length * 12.5) : 0
        },
        color () {
            return ['error', 'warning', 'light-green accent-4'][Math.floor(this.progress / 50)]
        },
    },
    async mounted(){
        this.$root.$toast       = this.$refs.toast.open
        if(this.$route.params.plan){
            let plan = this.cacheState.find(o => o.id === this.$route.params.plan);
            this.item.cpy_sub_typ_id = plan.value
        }
        // spoCode
        if(this.$route.params.spo_code){
            this.item.spo_code = this.$route.params.spo_code
        }
        if(this.$route.params.spo_uid){
            await this.fetchMySponsor(this.$route.params.spo_uid)
            this.item.firstName = this.mySponsor?.spo_customfields?.usr_firstName
            this.item.lastName  = this.mySponsor?.spo_customfields?.usr_lastName
            this.item.email     = this.mySponsor?.spo_customfields?.usr_email
            this.item.cpy_name  = this.mySponsor?.spo_customfields?.cpy_name
            this.item.spo_code  = this.mySponsor?.cpy_sponsor_code
            this.item.spo_uid   = this.mySponsor?.spo_uid
            this.sponsorCompany = this.mySponsor?.cpy_name
        }
        this.item.origin = 'UOR02'
        this.item.role = 'WORK_SUPERVISOR'
        if( this.$route.params.plan == 'SE'){
            this.item.origin = 'URO03'
            this.item.role = 'STUDENT'
        }
        this.getCaptcha()
    },
    methods: {
        ...mapActions(useSponsorshipStore, {fetchMySponsor: 'fetchMySponsor'}),
        register(){
            return new Promise( (resolve, reject) => {
                var formFile = new FormData()
                for (var prop in this.item) {
                    if( prop !== 'obj_file' ){
                        formFile.append(prop, this.item[prop])
                    }
                }
                if( this.item.obj_file ) {
                    formFile.append('docfile', this.item.obj_file, this.item.obj_file.name)
                }
                this.$http.post( '/register/', formFile ).then( () => {
                    //this.$router.push({ path: '/login' })
                    this.signSuccess = true
                    this.$root.$toast({ text: 'Inscription envoyée avec succès !' })
                    resolve('resolved')
                }).catch( (error) => {
                    if( error.response.data.message == 'invalid captcha' ){
                        this.$root.$toast({ color: 'error', text: 'Code Captcha invalide !', timeout:3000 })    
                    } else {
                        this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !' , error: error })
                    }
                    reject(error)
                })
            })
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        selectAdress(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                this.item.cpy_address = this.addressTab[this.searchAddress]?.item?.properties?.name
                this.item.cpy_town = this.addressTab[this.searchAddress]?.item?.properties?.city
                this.item.cpy_zip = this.addressTab[this.searchAddress]?.item?.properties?.postcode
                this.item.cpy_lat = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[1]
                this.item.cpy_lng = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[0]
            }
            this.searchAddress = ''
            this.addressTab.splice(0)
            this.$refs.captcha.focus()
        },
        getCaptcha(){
            this.captchaImageSrc = ''
            this.$http.customRequest({
                method: 'get', url: '../captcha/api/default', responseType: 'json'
            }).then( (response) => {
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Le fichier image n\'existe pas' })
                } else {
                    this.captchaImageSrc = response.data.img
                    this.item.key = response.data.key
                    this.captchaReady = true
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        getFileDocument(file){
            if( this.item.doc_name.length == 0 ){
                let nameTab = file.name.split(".")
                this.item.doc_name = nameTab[0]
            }
            this.item.doc_filename = file.name
        },
    }
}
</script>

<style lang="css">
/* Personnalisation code */

</style>