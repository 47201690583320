<template>
    <v-container fluid class="pa-0">
        <PageHeader :contextStyle="contextStyle">
            <template #right><v-btn small rounded color="primary" href="#/stores/0">Ajouter un dépôts</v-btn></template>
        </PageHeader>
    
        <StoreForm v-if="( $route.params.str_id !== undefined && parseInt( $route.params.str_id ) >= 0 )"/>
        <v-card v-else class="ma-4 rounded-lg">
            <StoresList />
        </v-card>
    
    </v-container>
    </template>
    
    <script>
    import PageHeader from '@/components/ui/PageHeader'
    import StoresList  from '@/components/stores/StoreList'
    import StoreForm   from '@/components/stores/StoreForm'
    
    export default {
        name: 'stores-page',
        props: ['contextStyle'],
        components: {
            PageHeader,
            StoresList, StoreForm
        },
        data: function () {
            return {
            }
        },
        computed: { },
        created(){},
        mounted: function (){
    
        },
        methods: {
    
        },
        beforeDestroy() {
    
        },
    }
    </script>