<template>
    <v-data-table v-bind:headers="headers" :items="userList" item-key="id" :search="search"
        items-per-page-text="Lignes par page" :hide-default-header="false"
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
            </v-row>
        </template>

        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.lastName }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text">{{ item.firstName }}</span>
                <br />
                <span class="ma-0 pl-0">
                    <v-icon small>mdi-account</v-icon>
                    <span class="grey--text">{{ item.username }}</span>
                    &nbsp;<v-icon small>mdi-email-outline</v-icon>
                    <span class="grey--text">{{ item.email }}</span>
                </span>
            </td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label color="primary" >{{ roles[item.role] }}</v-chip>
            </td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label color="secondary" >{{ item.cpy_name }}</v-chip>
            </td>
            <td class="text-right">
                <v-icon left class="mr-2">mdi-chart-histogram</v-icon>
                <span class="grey--text font-italic">{{ $date(item.updated_at).format('DD MMMM YYYY') }}</span>
                <v-btn depressed class="ml-2 rounded-xl" color="amber darken-1" :href="'/#/users/' + item.id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '@/stores/user'
import { cacheGetters } from "@/stores/cache.module"

export default {
    name: 'users-edit',
    computed: {
        ...mapState(useUserStore, {userList: 'itemList', roles: 'roles' })
    },
    data: function () {
        return {
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom', align: 'left', value: 'lastName'},
                //{ text: 'Mail', align: 'left', value: 'email'},
                { text: 'Role', value: 'role' },
                { text: 'Société', align: 'left', value: 'cpy_name'},
                { text: 'Modification', value: 'updatedAt' }
            ],
        }
    },
    async mounted () {
        await this.fetchItems('?include=usercompany&extended=viewall,withcompany')
    },
    methods: {
        ...mapActions(useUserStore, {fetchItems: 'fetchItems'}),
        getCacheType: cacheGetters.getTypes,
        getCacheState   : cacheGetters.getStates,
    },
}
</script>