<template>
<v-container>
    <v-app-bar class="mt-2 ml-0" color="secondary" prominent shrink-on-scroll light short elevation="0" style="z-index:100 !important;">
        <v-app-bar-title>
        <img v-if="(planId == 'SUB02')" width="110" :src="logo.plan2" style="cursor:pointer;" class="ml-1" />
        <img v-if="(planId == 'SUB03')" width="110" :src="logo.plan3" style="cursor:pointer;" class="ml-1" />
        <img v-if="(planId == 'SUB04')" width="110" :src="logo.plan4" style="cursor:pointer;" class="ml-1" />
        <img v-if="(planId == 'SUB01')" width="110" :src="logo.plan1" style="cursor:pointer;" class="ml-1" />
        </v-app-bar-title>
    </v-app-bar>
    <v-container v-if="(planId == 'SUB02')">
        <span style="color:white;" class="subtitle-1">Premier mois GRATUIT</span><br />
        <span style="color:white;" class="subtitle-1">Fonctions folium :</span><br />
        <span style="color:white;" class="subtitle-2">
            <ul>
                <li>Localisation sur carte des clients avec un lien google pour le guidage</li>
                <li>Filtre sur carte permettant un affichage optimisé</li>
                <li>Programmation des passages</li>
                <li>Fiche client avec le récapitulatif des interventions</li>
                <li>Création d’un bon de passage automatique que le client peut consulter</li>
                <li>Enregistrement des actions réalisées (taille, tonte…)</li>
                <li>possible d'enregistrer du personnel par déplacement chez un client.</li>
                <li>enregistrement des heures passées chez le client.</li>
                <li>Profils utilisateurs : 1 Chef d'entreprise - 5 Chefs de chantier / Conducteurs de travaux  - Agents illimités</li>             
            </ul>
        </span>
    </v-container>
    <v-container v-if="(planId == 'SUB03')">
        <span style="color:white;" class="subtitle-1">Premier mois GRATUIT</span><br />
        <span style="color:white;" class="subtitle-1">Fonctions Arbor :</span><br />
        <span style="color:white;" class="subtitle-2">
            <ul>
                <li>Localisation sur carte des clients avec un lien google pour le guidage</li>
                <li>Filtre sur carte permettant un affichage optimisé</li>
                <li>Programmation des passages</li>
                <li>Fiche client avec le récapitulatif des interventions</li>
                <li>Création d’un bon de passage automatique que le client peut consulter</li>
                <li>Enregistrement des actions réalisées (taille, tonte…)</li>
                <li>enregistrement des heures par tache (taille, tonte...) et par passage chez le client</li>
                <li>possible d'enregistrer du personnel par déplacement chez un client.</li>
                <li>Signature du client par bon de passage</li>
                <li>Profils utilisateurs : 1 Chef d'entreprise - 5 Chefs de chantier / Conducteurs de travaux  - Agents illimités</li>
            </ul>
    </span>
    </v-container>
    <v-container v-if="(planId == 'SUB04')">
        <span style="color:white;" class="subtitle-1">Premier mois GRATUIT</span><br />
        <span style="color:white;" class="subtitle-1">Fonctions Silva :</span><br />
        <span style="color:white;" class="subtitle-2">
            <ul>
                <li>Localisation sur carte des clients avec un lien google pour le guidage</li>
                <li>Filtre sur carte permettant un affichage optimisé</li>
                <li>Programmation des passages</li>
                <li>Fiche client avec le récapitulatif des interventions</li>
                <li>Création d’un bon de passage automatique que le client peut consulter</li>
                <li>Enregistrement des actions réalisées (taille, tonte…)</li>
                <li>enregistrement des heures par tache (taille, tonte...) et par passage chez le client</li>
                <li>possible d'enregistrer du personnel par déplacement chez un client.</li>
                <li>Signature du client par bon de passage</li>
                <li>Profils utilisateurs : 1 Chef d'entreprise - 5 Chefs de chantier / Conducteurs de travaux  - Agents illimités</li>
            </ul>
        </span>
    </v-container>
    <v-container v-if="(planId == 'SUB01')">
        <span style="color:white;" class="subtitle-1">Premier mois GRATUIT</span><br />
        <span style="color:white;" class="subtitle-1">Fonctions folium :</span><br />
        <span style="color:white;" class="subtitle-2">
            <ul>
                <li>Localisation sur carte des clients avec un lien google pour le guidage</li>
                <li>Filtre sur carte permettant un affichage optimisé</li>
                <li>Programmation des passages</li>
                <li>Fiche client avec le récapitulatif des interventions</li>
                <li>Création d’un bon de passage automatique que le client peut consulter</li>
                <li>Enregistrement des actions réalisées (taille, tonte…)</li>
                <li>possibilité de renseigner des heures par tache effectuées chez le client</li>
                <li>possibilité de renseigner des heures à du personnel</li>
                <li>Signature du client par bon de passage</li>
                <li>possibilité d'avoir plusieurs dépôts.</li>
                <li>facturationProfils</li>
                <li>Profils utilisateurs : 1 Chef d'entreprise - Chefs de chantier / Conducteurs de travaux illimités - Agents illimités</li>                
            </ul>
        </span>
    </v-container>
</v-container>
</template>

<script>
export default {
    name: 'sign-plan-component',
    props: ['planId'],
    components: { },
    data () {
        return {
            logo: {
                plan2: require('@/assets/logo_abo1.svg'),
                plan3: require('@/assets/logo_sub_arbor.svg'),
                plan4: require('@/assets/logo_sub_silva.svg'),
                plan1: require('@/assets/logo_sub_seminis.svg'),
            },
        }
    },
    mounted:function (){

    }
}
</script>