/* *****************************
    Objet : Document
******************************** */
import { cacheGetters } from "@/stores/cache.module"

export const docObj = {
    data: function () {
        return {
            objDocument: {
                doc_id: 0, doc_sta_id : 'GEN01', doc_xxx_id  : '', doc_table_id: 'act',
                doc_typ_id: '', doc_flags: 0, doc_mimetype: '', doc_filename: null, 
                doc_size : 0, doc_name : '', doc_description: '', doc_usr_id_created: 0,
                doc_created_at : '', doc_updated_at: 0, obj_file : null
            },
            objDocumentClone: {},
            listDocument: [],
            metaDocument: {},
            filterDocument: {
                withtask: true
            },
        }
    },
    mounted: function (){
        this.objDocumentClone = JSON.stringify( this.objDocument )
    },
    methods: {
        getDocumentFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage
            if( !this.filterDocument.withtask ){
                searchCriteria += ''
            } else {
                searchCriteria += '&extended=withtask'
                for( var prop in filter ){
                    if( filter[prop] !== undefined && filter[prop].length > 0 ){
                        searchCriteria += '&' + prop + '=' + filter[prop]
                    }
                }
            }
            return searchCriteria
        },
        // Get Document
        getDocumentShow(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/documents/' + this.objDocument.doc_id).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.objDocument[prop] !== undefined ){
                            this.objDocument[prop] = response.data.data[prop] 
                        }
                    }
                    this.objDocument.doc_created_at = ( this.objDocument.doc_created_at == '2000-01-01' ) ? null : this.objDocument.doc_created_at
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getDocumentIndex(page, perPage, order){
            return new Promise( (resolve, reject) => {
                order = order || ''
                this.listDocument = []
                let searchCriteria = this.getDocumentFilter(page, perPage)

                this.$http.get( '/documents/' + searchCriteria + order ).then( (response) => {
                    this.metaDocument = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listDocument = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putDocumentUpdate(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/documents/' + this.objDocument.doc_id, this.objDocument ).then( (response) => {
                    this.objDocument.doc_id = response.data.data.doc_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postDocumentStore(){
            return new Promise( (resolve, reject) => {
                var formFile = new FormData()
                for (var prop in this.objDocument) {
                    if( prop !== 'obj_file' ){
                        formFile.append(prop, this.objDocument[prop])
                    }
                }
                if( this.objDocument.obj_file ) {
                    formFile.append('docfile', this.objDocument.obj_file, this.objDocument.obj_file.name)
                }

                this.$http.post( '/documents', formFile ).then( (response) => {
                    this.objDocument.doc_id = response.data.data.doc_id
                    resolve('resolved')
                }).catch( (error) => {
                    reject(error)
                })
            })
        },
        deleteObjDocument(){

        },
        setDocumentFilter(){

        },
        cleanObjDocument(){
            var DocumentClone = JSON.parse( this.objDocumentClone )
            var prop
            for( prop in DocumentClone ){
                if( this.objDocument[prop] !== undefined ){
                    this.objDocument[prop] = DocumentClone[prop]
                }
            }
        }
    }
}