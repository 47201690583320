const ChMain        = () => import('@/layouts/Main.vue') 
const ChDasboard    = () => import('@/pages/Dashboard.vue')
const ChTasks       = () => import('@/pages/Tasks.vue')
const ChPlanning    = () => import('@/pages/Planning.vue')
const ChMap         = () => import('@/pages/Map.vue')
const SiteSlider    = () => import('@/components/sites/SiteSlider.vue')
const ChEditTask    = () => import(/* webpackChunkName: "build-glob" */ '@/components/tasks/TaskForm.vue')
const ChViewSite    = () => import(/* webpackChunkName: "build-glob" */ '@/components/sites/SiteView.vue')
const ChEditSite    = () => import(/* webpackChunkName: "build-glob" */ '@/components/sites/SiteForm.vue')
const ChEditCustomer= () => import(/* webpackChunkName: "build-glob" */ '@/components/customers/CustomerForm.vue')

export default {
menu : [
    { 'href': '/dashboard',       'scope':'Generic', 'right': 'index', 'title': 'Tableau de bord', 'icon': 'mdi-view-dashboard' },
    { 'href': '/map',             'scope':'Map',     'right': 'index', 'title': 'Carte', 'icon': 'mdi-map-outline' },
//    { 'href': '/planning',        'scope':'Generic', 'right': 'index', 'title': 'Calendrier', 'icon': 'mdi-calendar-check' },
    { 'href': '/customers',       'scope':'Generic', 'right': 'index', 'title': 'Mairies', 'icon': 'mdi-face-agent' },
    //{ 'href': '/sites',           'scope':'Generic', 'right': 'index', 'title': 'Sites', 'icon': 'mdi-map-marker-multiple' },
    { 'href': '/tasks',           'scope':'Generic', 'right': 'index', 'title': 'Interventions', 'icon': 'mdi-traffic-cone' },
//    { divider: true },
//    { 'header': 'Reporting', 'scope':'Report', 'right': 'index' },
//    { 'href': '/reports',    'scope':'Report', 'right': 'index', 'title': 'Export', 'icon': 'mdi-file-chart' },
    { divider: true },
    { 'header': 'Paramètres',    'scope':'Setting',   'right': 'index',  'title': 'Paramètres', 'icon': 'mdi-cogs', items: [
        { 'href': '/stores',     'scope':'Store',     'right': 'index',  'title': 'Entrepôts', 'icon': 'mdi-store-marker-outline' },
        { 'href': '/tasktypes',  'scope':'TaskType',  'right': 'update', 'title': 'Type de taches', 'icon': 'mdi-file-tree' },
        { 'href': '/states',     'scope':'State',     'right': 'update', 'title': 'Gestion états', 'icon': 'mdi-tag-multiple' },
        { 'href': '/types',      'scope':'Type',      'right': 'update', 'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type', 'role': ['ADMIN_USER' ] },
    ] },
    { divider: true },
    { 'header': 'Systeme', 'scope':'Setting',     'right': 'index'},
    { 'href': '/users',          'scope':'User',   'right': 'store', 'title': 'Utilisateurs', 'icon': 'mdi-account' },
    { 'href': '/companies',      'scope':'Entity',    'right': 'destroy', 'title': 'Sociétés', 'icon': 'mdi-domain', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    { 'href': '/importdata',  'title': 'Import / synchro',  'icon': 'mdi-file-upload', 'role': ['ADMIN_USER', 'SUPER_USER'] },
//    { 'href': '/sponsorship',    'scope':'User',   'right': 'destroy', 'title': 'Parrainage', 'icon': 'mdi-star-outline' },
    //{ 'href': '/importdata',  'title': 'Import / synchro',  'icon': 'mdi-file-upload', 'role': ['ADMIN_USER', 'SUPER_USER'] },
    //{ 'href': '/jobs',  'title': 'Taches de fond', 'icon': 'mdi-progress-wrench', 'role': ['ADMIN_USER', 'SUPER_USER'] },
],
routes : [
    { name:'login', path: '/login',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'setpass', path: '/auth/set-password/:token',   component: require('./layouts/Password.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'signin', path: '/signin/:plan',   component: require('./layouts/SignIn.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'signin-spo-code', path: '/signin/:plan/code/:spo_code',   component: require('./layouts/SignIn.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'signin-spo-uid', path: '/signin/:plan/spolink/:spo_uid',   component: require('./layouts/SignIn.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'logout', path: '/logout',   component: require('./layouts/Logout.vue').default },
    { name: '404', path: '*', component: require('./layouts/404.vue').default },
    { name: '401', path: '/401', component: require('./layouts/401.vue').default },
    { path: '/', component: ChMain,
        children: [
            { name: 'home', path: '/', redirect: '/dashboard' },
            { name: 'dashboard', path: '/dashboard', 
                components: { default: ChDasboard, modal: SiteSlider },
                children: [
                  //{ name: 'dash_slide_prev', path: 'sheet/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  //{ name: 'dash_slide_edit', path: 'sheet/:tsk_id/modifier', components: { content: ChEditCard } },
                ]
            },
            { name: 'tasks-list', path: '/tasks', 
                components: { default: ChTasks, modal: SiteSlider },
                children: [
                    { name: 'tsk-slide-view', path: 'site/:sit_id/task/:tsk_id', components: { content: ChEditTask } }
                  
                ]
            },
            { name: 'tasks-edit',      path: '/tasks/:tsk_uid',     component: require('@/pages/Tasks.vue').default },
            { name: 'planning-view', path: '/planning', 
                components: { default: ChPlanning, modal: SiteSlider },
                children: [
                    { name: 'planning-view-slide-site', path: 'site/:sit_id/tasks', meta: { component: 'ChViewSite' }, components: { content: ChViewSite } },
                    { name: 'planning-view-slide-task', path: 'site/:sit_id/tasks/:tsk_uid', meta: { component: 'ChEditTask' }, components: { content: ChEditTask } },
                  
                ]
            },
            { name: 'customers-list', path: '/customers',         component: require('./pages/Customers.vue').default },
            //{ name: 'customers-edit', path: '/customers/:ent_id', component: require('./pages/Customers.vue').default },
            { name: 'customers-edit', path: '/customers/:ent_id', components: { default: require('./pages/Customers.vue').default, modal: SiteSlider },
                children: [
                    { name: 'customers-edit-slide-site', path: 'site/:sit_id/tasks', meta: { component: 'ChViewSite' }, components: { content: ChViewSite } },
                    { name: 'customers-edit-slide-task', path: 'site/:sit_id/tasks/:tsk_uid', meta: { component: 'ChEditTask' }, components: { content: ChEditTask } },
                    { name: 'customers-edit-slide-site-edit', path: 'site/:sit_id/edit', meta: { component: 'ChEditSite' }, components: { content: ChEditSite } },
                  
                ]
            },
            { name: 'map-view', path: '/map', 
                components: { default: ChMap, modal: SiteSlider },
                children: [
                    { name: 'map-view-slide-site', path: 'site/:sit_id/tasks', meta: { component: 'ChViewSite' }, components: { content: ChViewSite } },
                    { name: 'map-view-slide-task', path: 'site/:sit_id/tasks/:tsk_uid', meta: { component: 'ChEditTask' }, components: { content: ChEditTask } },
                    { name: 'map-view-slide-site-edit', path: 'site/:sit_id/edit/:lat/:lng', meta: { component: 'ChEditSite' }, components: { content: ChEditSite } },
                    { name: 'map-view-slide-customer-create', path: 'site/:sit_id/customer/:ent_id/:lat/:lng', meta: { component: 'ChEditCustomer' }, components: { content: ChEditCustomer } },
                    { name: 'map-view-slide-customer-edit', path: 'site/:sit_id/customer/:ent_id', meta: { component: 'ChEditCustomer' }, components: { content: ChEditCustomer } },
                ]
            },
            //{ name: 'sites',          path: '/sites',             component: require('./pages/Sites.vue').default },
            { name: 'sites-list', path: '/sites/', components: { default: require('./pages/Sites.vue').default, modal: SiteSlider },
                children: [
                    { name: 'sites-list-slide-site', path: 'site/:sit_id/tasks', meta: { component: 'ChViewSite' }, components: { content: ChViewSite } },
                    { name: 'sites-list-slide-task', path: 'site/:sit_id/tasks/:tsk_uid', meta: { component: 'ChEditTask' }, components: { content: ChEditTask } },
                    { name: 'sites-list-slide-site-edit', path: 'site/:sit_id/edit', meta: { component: 'ChEditSite' }, components: { content: ChEditSite } },
                  
                ]
            },
            //{ name: 'sites-edit',     path: '/sites/:sit_id',     component: require('./pages/Sites.vue').default },

            { name: 'reports',       path: '/reports',             component: require('./pages/Reports.vue').default },
            
            { name: 'users',         path: '/users',             component: require('./pages/Users.vue').default },
            { name: 'useredit',      path: '/users/:id',         component: require('./pages/Users.vue').default },
            { name: 'companies',     path: '/companies',         component: require('./pages/Companies.vue').default },
            { name: 'companiesedit', path: '/companies/:cpy_id', component: require('./components/companies/CompaniesForm.vue').default },

            { name: 'contacts',       path: '/contacts',          component: require('./pages/Contacts.vue').default },
            { name: 'contacts_view',  path: '/contacts/:ctt_id',  component: require('./pages/Contacts_edit.vue').default },
            { name: 'stores',         path: '/stores',            component: require('./pages/Stores.vue').default },
            { name: 'stores-edit',    path: '/stores/:str_id',    component: require('./pages/Stores.vue').default },
            { name: 'tasktypes',      path: '/tasktypes',         component: require('./pages/TaskTypes.vue').default },
            { name: 'tasktypesedit',  path: '/tasktypes/:tty_id', component: require('./components/tasktypes/TaskTypesForm.vue').default },
            { name: 'states',         path: '/states',            component: require('./pages/States.vue').default },
            { name: 'statesedit',     path: '/states/:sta_id',    component: require('./components/states/StatesForm.vue').default },
            { name: 'types',          path: '/types',             component: require('./pages/Types.vue').default },
            { name: 'typesedit',      path: '/types/:typ_id',     component: require('./pages/Types.vue').default },

            { name: 'sponsorship',    path: '/sponsorship',       component: require('./pages/SponsorShip.vue').default },
            { name: 'importdata',     path: '/importdata',        component: require('./pages/ImportData.vue').default },

            { name: 'forbidden',    path: '/forbidden', component: require('./layouts/403.vue').default },
            
        ]
    }
]


}