<template>
    <v-container fluid class="pa-0">
    <!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card">
        <v-card-title >
            Client
            <v-spacer></v-spacer>
            <v-chip outlined color="secondary">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.updatedAt).format('DD MMMM YYYY') }}
            </v-chip>
        </v-card-title>
        <v-layout row wrap class="layout-flex-2" style="">
            <v-flex xs8>
                <v-layout row wrap class="layout-flex-2" style="">
                <v-flex xs12>
                    <v-text-field outlined dense class="rounded-lg" label="Nom"    v-model="item.ent_name" ></v-text-field>
                </v-flex>
                <v-flex xs6>
                    <v-text-field prepend-inner-icon="mdi-phone" outlined dense class="rounded-lg" label="Tel" v-model="item.ent_customfields.phone"></v-text-field>
                </v-flex>
                <v-flex xs6>
                    <v-text-field prepend-inner-icon="mdi-cellphone-basic" outlined dense class="rounded-lg" label="Tel mobile" v-model="item.ent_customfields.mobilephone"></v-text-field>
                </v-flex>
                <v-flex xs12>
                    <v-text-field prepend-inner-icon="mdi-card-account-mail-outline" outlined dense class="rounded-lg" label="Email" v-model="item.ent_customfields.ent_email"></v-text-field>
                </v-flex>
                <!-- right side -->
                <v-flex xs12>
                    <v-autocomplete
                        v-model.trim="searchAddress"
                        label="Rechercher adresse"
                        :items="addressTab"
                        item-value="id" item-text="label"
                        :search-input.sync="search"
                        @change="selectAdress" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
                        append-icon="mdi-magnify" filled outlined dense clearable no-filter
                        class="inline rounded-lg shrink expanding-search"
                        background-color="white">
                    </v-autocomplete>
                </v-flex>
                <v-flex xs6>
                    <v-text-field ref="address" outlined dense class="rounded-lg" label="Rue / complément" v-model="item.ent_customfields.ent_address"></v-text-field>
                </v-flex>
                <v-flex xs4>
                    <v-text-field outlined dense class="rounded-lg" label="Ville" v-model="item.ent_customfields.ent_town"></v-text-field>
                </v-flex>
                <v-flex xs2>
                    <v-text-field outlined dense class="rounded-lg" label="Code postal" v-model="item.ent_customfields.ent_zip"></v-text-field>
                </v-flex>
                <v-flex xs4>
                    <v-switch v-if="$route.params.ent_id == 0" v-model="createSite" label="Créer un site avec la même adresse"></v-switch>
                </v-flex>
            </v-layout>
            </v-flex>
            <v-flex xs4 class="pb-5">
                <div class="elevation-0" style="max-width:350px; margin-left:auto; margin-right:auto;">
                <v-carousel v-show="( itemsCarousel.length )" :height="'auto'" ref="resizedImage" class="ml-2 mr-2 elevation-3 rounded-lg">
                    <v-carousel-item
                        v-for="(item,i) in itemsCarousel" :key="i" :src="item.src"
                        reverse-transition="fade-transition" transition="fade-transition" @click="downloadDocument(item)"
                        style="cursor:pointer;">
                        <v-icon left :color="(item.doc_pinned) ? 'red' : 'grey'" title="">
                            {{ (item.doc_pinned) ? 'mdi-heart' : 'mdi-heart-outline' }}
                        </v-icon>
                    </v-carousel-item>
                </v-carousel>
                </div>
            </v-flex>
        </v-layout>
        <v-card-actions>
            <v-btn color="red" v-if="item.uid" @click="deleteItem(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="$root.$permissions.Customer.store" color="success" :loading="loading.fetchData" @click.native="saveItem()" :disabled="loading.fetchData">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/customers/">Retour</v-btn>
        </v-card-actions>
    </v-card>
    <v-divider></v-divider>
    <fieldset fluid class="mt-5 ma-2 pa-0 elevation-0 rounded-xxl micorise-v-card" style="background-color:#E0F2F1">
        <legend style="margin-left: 1em; padding: 0.2em 0.8em ">Liste des sites / Ajouter un site</legend>
        <SitesList v-if="!loading.fetchData && item.ent_id" :sit_ent_id="item.ent_id" :searchBar="false" class="rounded-xxl" style="background-color:#E0F2F1"/>
    </fieldset>
    <fieldset fluid class="mt-5 ma-2 pa-0 elevation-0 rounded-xxl micorise-v-card" style="background-color:#F9FBE7">
        <legend style="margin-left: 1em; padding: 0.2em 0.8em ">Liste des actions</legend>
        <TasksList v-if="!loading.fetchData && item.ent_id" :sit_ent_id="item.ent_id" :searchBar="true" :addItemButton="false" class="rounded-xxl" style="background-color:#F9FBE7"/>
    </fieldset>
    </v-container>
</template>

<script>
import SitesList  from '@/components/sites/SiteList'
import TasksList  from '@/components/tasks/TaskList'
//import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { mapWritableState, mapActions, mapState } from 'pinia'
import { useEntityStore } from '@/stores/entity'
import { useSiteStore } from '@/stores/site'
import { useDocumentStore } from '@/stores/document'

import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'Customer-Edit',
    components: { SitesList, TasksList },
    computed: {
        ...mapWritableState(useEntityStore, { item: 'item' }),
        ...mapWritableState(useSiteStore, { site: 'item' }),
        ...mapState(useDocumentStore, { documentList: 'itemList' }),
        progress () {
            return (this.item.password) ? Math.min(100, this.item.password.length * 12.5) : 0
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val && val.length >= 3 ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    data: function () {
        return {
            loading: { fetchData: true },
            createSite: true,
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            itemsCarousel: []
        }
    },
    async beforeUpdate(){ },
    async mounted () {
        this.setDocumentList( [] )
        await this.fetchItem( this.$route.params.ent_id, '?ent_sta_id=GEN01' )
        this.loading.fetchData = false
        if( this.$route.params.lat && this.$route.params.lng ){
            this.adresseFromPoint( { lat: this.$route.params.lat, lng: this.$route.params.lng } )
        }
        if( parseInt( this.$route.params.ent_id ) > 0 ){
            await this.fetchDocument('?sit_ent_id=' + this.item.ent_id + '&extended=withtasks&doc_typ_id=DOC03&doc_pinned=1')
            this.getCarouselImage()
        }
    },
    methods: {
        ...mapActions(useEntityStore, { fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem' }),
        ...mapActions(useSiteStore, { storeSite: 'storeItem'}),
        ...mapActions(useDocumentStore, { fetchDocument: 'fetchItems', downloadItem: 'downloadItem', setDocumentList: 'SET_ITEMS' }),
        async saveItem () {
            // Enregistrement du formulaire
            this.loading.fetchData = true
            if( parseInt( this.$route.params.ent_id ) > 0 ){
                await this.updateItem()
            } else {
                await this.storeItem()
                if(this.createSite && this.item.ent_id){
                    this.site.sit_address = this.item.ent_customfields.ent_address
                    this.site.sit_town   = this.item.ent_customfields.ent_town
                    this.site.sit_zip    = this.item.ent_customfields.ent_zip
                    this.site.sit_lat    = this.item.ent_customfields.ent_lat
                    this.site.sit_lng    = this.item.ent_customfields.ent_lng
                    this.site.sit_name   = this.item.ent_name
                    this.site.sit_ent_id = this.item.ent_id
                    this.site.sit_sta_id = 'GEN01'
                    this.storeSite()
                }
            }
            if( this.$route.matched[1].name == 'map-view' ){
                filterUpdateService.sendFilter('filter')
                this.$router.push({ name: this.$route.matched[1].name, params: { ent_id: this.$route.params.ent_id } })
            } else {
                this.$router.push({ path: '/customers' })
            }
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        selectAdress(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                this.item.ent_customfields.ent_address = this.addressTab[this.searchAddress]?.item?.properties?.name
                this.item.ent_customfields.ent_town = this.addressTab[this.searchAddress]?.item?.properties?.city
                this.item.ent_customfields.ent_zip = this.addressTab[this.searchAddress]?.item?.properties?.postcode
                this.item.ent_customfields.ent_lat = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[1]
                this.item.ent_customfields.ent_lng = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[0]
            }
            this.searchAddress = ''
            this.addressTab.splice(0)
            this.$refs.address.focus()
        },
        adresseFromPoint(latLng){
            this.$http.customRequest( {
                headers: '',
                baseURL: '',
                method: 'get',
                url: "https://api-adresse.data.gouv.fr/reverse/?lon=" + latLng.lng + "&lat=" + latLng.lat + ""
            }).then( (response) => {
                if( response.data && response.data.features && response.data.features[0] ){

                    this.item.ent_customfields.ent_address = response.data?.features[0]?.properties.name
                    this.item.ent_customfields.ent_town    = response.data?.features[0]?.properties.city
                    this.item.ent_customfields.ent_zip     = response.data?.features[0]?.properties.postcode
                    this.item.ent_customfields.ent_lat     = latLng.lat
                    this.item.ent_customfields.ent_lng     = latLng.lng
                    this.item.ent_lat     = latLng.lat
                    this.item.ent_lng     = latLng.lng
                }
            })
        },
        async getCarouselImage(){
            this.itemsCarousel = []
            for( const pic in this.documentList ){
                if( this.documentList[pic].doc_typ_id == 'DOC03' ){
                    const img = await this.downloadItem(this.documentList[pic].doc_id, null, '?type=small')
                    if( img.blob && img.blob.size > 0 ){
                        let reader = new FileReader()
                        reader.readAsDataURL(img.blob)
                        reader.onloadend = () => {
                            const base64data = reader.result
                            this.documentList[pic].src = base64data
                            this.itemsCarousel.push( this.documentList[pic] )
                        }
                    }
                    //this.itemsCarousel.unshift( this.documentList[pic] )
                }
            }
        },
    }
}
</script>