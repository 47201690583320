var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"max-width":"100%"},attrs:{"xs12":""}},[_c('v-card',{staticClass:"elevation-1 ma-2"},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"green"}},[_c('v-toolbar-title',[_c('v-icon',[_vm._v("mdi-file-excel")]),_vm._v("Export par tache")],1),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-container',{attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"align-center":"","wrap":""}},[_c('v-flex',{staticClass:"pl-2 pr-2 pa-0",attrs:{"xs6":""}}),_c('v-flex',{staticClass:"pl-2 pr-2 pa-0 text-right",attrs:{"xs6":""}},[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"outlined":"","color":"indigo"},on:{"click":_vm.addDates}},[_c('v-icon',[_vm._v("mdi-calendar-plus")]),_vm._v("Ajouter des dates ")],1)],1),_c('v-flex',{staticClass:"pl-2 pr-2 pa-0",attrs:{"xs12":""}},[_c('AutocompleteMulti',{attrs:{"title":'Type de tache',"ressource":'tasktypes',"listObject":_vm.reports.tasks.tsk_tty_id,"listParent":_vm.filterSource.tsk_tty_id,"fields":{ 
                            index: 'tty_id', text:'tty_name', autofocus: true, outlined: true,
                            search:'tty_name', multiSelect:true, prefetch: false, params:'' 
                    }},on:{"update:listObject":function($event){return _vm.$set(_vm.reports.tasks, "tsk_tty_id", $event)},"update:list-object":function($event){return _vm.$set(_vm.reports.tasks, "tsk_tty_id", $event)},"update:listParent":function($event){return _vm.$set(_vm.filterSource, "tsk_tty_id", $event)},"update:list-parent":function($event){return _vm.$set(_vm.filterSource, "tsk_tty_id", $event)}}})],1),_c('v-flex',{staticClass:"pl-2 pr-2 pa-0",attrs:{"xs6":""}},[_c('AutocompleteMulti',{attrs:{"title":'Etats',"listObject":_vm.reports.tasks.tsk_sta_id,"listParent":_vm.filterSource.tsk_sta_id,"fields":{ 
                            index: 'sta_id', text:'sta_label', autofocus: true, outlined: true,
                            search:'sta_label', multiSelect:true, prefetch: false, params:'' 
                    }},on:{"update:listObject":function($event){return _vm.$set(_vm.reports.tasks, "tsk_sta_id", $event)},"update:list-object":function($event){return _vm.$set(_vm.reports.tasks, "tsk_sta_id", $event)},"update:listParent":function($event){return _vm.$set(_vm.filterSource, "tsk_sta_id", $event)},"update:list-parent":function($event){return _vm.$set(_vm.filterSource, "tsk_sta_id", $event)}}})],1),_c('v-flex',{staticClass:"pl-2 pr-2 pa-0",attrs:{"xs6":""}},[_c('AutocompleteMulti',{attrs:{"title":'Interventions',"listObject":_vm.reports.tasks.tsk_typ_id,"listParent":_vm.filterSource.tsk_typ_id,"fields":{ 
                            index: 'typ_id', text:'typ_name', autofocus: true, outlined: true,
                            search:'typ_name', multiSelect:true, prefetch: false, params:'' 
                    }},on:{"update:listObject":function($event){return _vm.$set(_vm.reports.tasks, "tsk_typ_id", $event)},"update:list-object":function($event){return _vm.$set(_vm.reports.tasks, "tsk_typ_id", $event)},"update:listParent":function($event){return _vm.$set(_vm.filterSource, "tsk_typ_id", $event)},"update:list-parent":function($event){return _vm.$set(_vm.filterSource, "tsk_typ_id", $event)}}})],1),_vm._l((_vm.datesRange),function(item,index){return [_c('v-flex',{key:'min' + index,staticClass:"pl-2 pr-2",attrs:{"xs5":""}},[_c('DatePicker',{attrs:{"label":"Executé le","objectForm":item,"fieldName":"min_tsk_date_plan"}})],1),_c('v-flex',{key:'max' + index,staticClass:"pl-2 pr-2",attrs:{"xs5":""}},[_c('DatePicker',{attrs:{"label":"Jusqu'au","objectForm":item,"fieldName":"max_tsk_date_plan"}})],1),_c('v-flex',{key:'del' + index,staticClass:"pl-2 pr-2 text-right",attrs:{"xs2":""}},[((!item['min_tsk_date_plan'] || !item['max_tsk_date_plan']))?_c('v-btn',{staticClass:"ma-2",attrs:{"title":"Un ou plusieurs champs non renseigné(s)","outlined":"","fab":"","color":"orange"}},[_c('v-icon',[_vm._v("mdi-alert-outline")])],1):_vm._e(),(index)?_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","fab":"","color":"red"},on:{"click":function($event){return _vm.removeDate(index)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e()],1)]})],2)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"success","disabled":_vm.loading.getItem},on:{"click":function($event){_vm.downloadReport('tasks', 'per_page=false&extended=withtasktype,withsubarea,withtasktypeinter,withstate' + _vm.rangeToUrl(_vm.datesRange), 'get')}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-content-save")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Telecharger")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }