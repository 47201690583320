<template>
<v-container fluid class="pa-1">
    <v-data-table :headers="headers" :items="items" :items-per-page="10" class="ma-4 rounded-lg elevation-2" >
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="12" md="12" class="pa-0">
                    <v-toolbar flat>
                        <v-toolbar-title v-show="(title)">
                            <v-alert class="mb-0" border="left" dense colored-border type="info">{{title}}</v-alert>
                        </v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="success" outlined :loading="loading.getXls" class="ma-2" @click="downloadEvents()">
                                    <v-icon dark>mdi-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Telecharger xlsx</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    <v-btn v-if="isAuthorized( 'eventedit', 'edit' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openEvent(0)"> <!-- :disabled="!routeState.edit" -->
                        Ajouter<v-icon>mdi-playlist-edit</v-icon>
                    </v-btn>
                    </v-toolbar>
                </v-col>
            </v-row>
        </template>        
        <template v-slot:[`item.eve_typ_id`]="{ item }">
            <v-chip label outlined :color="typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_color">{{ typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.eve_sta_id`]="{ item }">
            <v-chip label outlined color="green">{{ getCacheState( item.eve_sta_id.substring(0, 3), item.eve_sta_id ).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.eve_date_start`]="{ item }">
            <v-chip label outlined>{{ $date(item.eve_date_start).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.eve_id`]="{ item }">
            <v-icon left color="orange" v-if="($root.$permissions.Quotation.update || $root.$permissions.Event.update)" @click="openEvent(item.eve_id)">mdi-pencil</v-icon>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !loading.getEvent )" color="error" icon="mdi-alert">
            Aucun evenement
        </v-alert>
        </template>
    </v-data-table>
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Evenement</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <EditEvent class="elevation-2" 
                v-if="modalEvent" :switchModal="switchModal"
                :xxxId="eve_xxx_id" :eveId="eveInfo.eve_id" :eveTable="table_id" :eveType="typfilter">
            </EditEvent>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import EditEvent  from '@/components/events/EventForm.vue'
import { cacheGetters } from "@/stores/cache.module"
import { usrGetters }   from "@/stores/user.module"
import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'tab_event',
    props: ['toast', 'table_id', 'eve_xxx_id', 'title', 'typfilter', 'stafilter', 'routeState', 'genfilter'],
    components: { EditEvent },
    data: function () {
        return {
            loading: { uploadBtn: false, rebuildBtn: false, getEvent: false, getXls: false },         // Enregistrement en cours...
            saveProgress: false,         // Enregistrement en cours...
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            headers: [
            { text: 'Description', align: 'left', value: 'eve_code'},
            { text: 'Date', align: 'left', value: 'eve_date_start'},
            { text: 'Type', align: 'left', value: 'eve_typ_id'},
            { text: 'Statut', value: 'eve_sta_id' },
            { text: 'Action', value: 'eve_id' }
            ],
            items: [],
            enableAdd: true,
            modalEvent : false,
            prv_id: 0,
            eve_id: 0,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: this.table_id, eve_xxx_id  : this.eve_xxx_id },
            modalPagin : true,
            isAuthorized: usrGetters.getScopeRight
        }
    },
    created(){
        // modification au niveau du filtre
        this.subscribeFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.getEvent()
            }
        })
    },
    mounted: function (){
        if( this.eve_xxx_id !== undefined || this.eve_xxx_id !== 'all' ){
            this.getEvent()
        }
    },
    watch: {
    },
    methods: {
        getEvent(){
            this.items = []
            this.loading.getDocument = true
            //var searchCriteria = '?per_page=false' //page='+ this.pagination.page +'&
            //searchCriteria    += '&eve_xxx_id=' +  this.eve_xxx_id + '&eve_table_id=' + this.table_id
            //searchCriteria    += ( this.typfilter ) ? '&eve_typ_id=%' + this.typfilter + '%' : ''

            let url    = '?per_page=false&eve_table_id=' + this.table_id
            url        += (this.eve_xxx_id !== 'all') ? '&eve_xxx_id=' +  this.eve_xxx_id : ''
            url        += ( this.typfilter ) ? '&eve_typ_id=%' + this.typfilter + '%' : ''
            url        += ( this.stafilter ) ? '&eve_sta_id=%' + this.stafilter + '%' : ''
            if( this.genfilter !== false ){
                let option = cacheGetters.getFilterUrl('eve')
                url    += ( option.length ) ? '&' + option : ''
            }
            this.$http.get( '/events/' + url ).then( (response) => {
                if( response?.data?.meta?.total !== undefined && response?.data?.meta?.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response?.data?.meta?.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                this.loading.getEvent = false
                // Open event id requested
                if( ( this.$route.params.eve_id ) && this.$route.params.eve_id > 0 ){
                    this.items.forEach( (item) => {
                        if( item.eve_id == this.$route.params.eve_id){
                            this.openEvent(item.eve_id)
                        }
                    })
                }
            })
        },
        openEvent(id){
            this.eveInfo.eve_id = id
            this.switchModal('modalEvent', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalEvent', 1)
            }
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.ctp_id = 0
            }
            if( refresh ){ 
                this.getEvent()
            }
        },
        async downloadEvents(){
            this.loading.getXls = true
            let report    = 'events'
            let method    = 'get'
            let fileName  = 'rapport'

            let url    = '?per_page=false&eve_table_id=' + this.table_id + '&extended=witheventstate,witheventtype'
            url        += (this.eve_xxx_id !== 'all') ? '&eve_xxx_id=' +  this.eve_xxx_id : ''
            url        += ( this.typfilter ) ? '&eve_typ_id=%' + this.typfilter + '%' : ''

            if( this.genfilter !== false ){
                let option = cacheGetters.getFilterUrl('eve')
                url    += ( option.length ) ? '&' + option : ''
            }
            this.$http.customRequest({ method: method, url: '/report/' + report + '/' + url, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, fileName + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getXls = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getXls = false
            })
        },
        getCacheState   : cacheGetters.getStates
    },
    beforeDestroy() {
        this.subscribeFilter.unsubscribe()
    }
}
</script>