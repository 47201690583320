<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
        <template #right><v-btn small rounded color="primary" href="#/customers/0">Ajouter un client</v-btn></template>
    </PageHeader>

    <CustomerForm v-if="( $route.params.ent_id !== undefined && parseInt( $route.params.ent_id ) >= 0 )"/>
    <v-card v-else class="ma-4 rounded-lg">
        <CustomersList />
    </v-card>

</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import CustomersList from '@/components/customers/CustomerList'
import CustomerForm from '@/components/customers/CustomerForm'

//import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'entities-page',
    props: ['contextStyle'],
    components: {
        PageHeader,
        CustomersList, CustomerForm
    },
    data: function () {
        return {

        }
    },
    computed: { },
    created(){},
    mounted: function (){

    },
    methods: {

    },
    beforeDestroy() {

    },
}
</script>